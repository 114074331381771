import { useQuery } from '@tanstack/react-query';
import {
  ReviewPlatformsQueryResult,
  ReviewStatsQueryResult,
} from 'lib/types/review';
import { BookingGateway } from 'services/http/booking-gateway';
import { sessionIdService } from 'services/session-id/session-id.service';

const useGetReviewStats = () => {
  const URL = '/public/inventory/trip/review/stats';

  const sessionId = sessionIdService.get();

  return useQuery(
    ['get-review-stats', sessionId],
    async () => {
      const response = await BookingGateway.get<ReviewStatsQueryResult>(URL);
      return response;
    },
    { enabled: !!sessionId.length, staleTime: Infinity }
  );
};

export { useGetReviewStats };
