import { useState } from 'react';
import { Button } from '../../Button/Button';
import { Input } from '../../Input/Input';
import { Promotion, PromotionObject } from 'lib/types/promotion';
import { useLocation } from 'react-router-dom';
import { stagePathConfig } from 'data/stage';
import { InfoIcon } from 'assets/icons/Info';

type Props = {
  addPromo: (promo: string) => void;
  addingPromotionLoading: boolean;
  shouldNotLetUserAddPromotions: boolean;
  errorMessagePromo: string;
  setErrorMessagePromo: any;
  allPromotions: Promotion;
  appliedPromotions: PromotionObject[];
};

const PromoForm = ({
  addPromo,
  addingPromotionLoading,
  shouldNotLetUserAddPromotions,
  errorMessagePromo,
  setErrorMessagePromo,
  allPromotions,
  appliedPromotions,
}: Props) => {
  const [promoCode, setPromoCode] = useState<string>('');
  const { pathname } = useLocation();
  const isInPaymentStage = pathname === stagePathConfig.payment;

  const clickHandler = () => {
    if (promoCode.trim()) {
      if (checkDuplicatePromo(promoCode)) {
        setErrorMessagePromo('promotion is already in use');
      } else {
        setErrorMessagePromo('');
        setPromoCode('');
        addPromo(promoCode);
      }
    } else {
      setErrorMessagePromo('Please enter the Promo Code.');
    }
  };

  const changePromo = (e: any) => {
    setPromoCode(e);
    if (e) {
      setErrorMessagePromo('');
    }
  };

  const keyUpHandler = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.code === 'Enter') {
      clickHandler();
    }
  };

  const extractPrmotionCodes = () => {
    const result = [];
    if (
      allPromotions.deposit?.items &&
      allPromotions.deposit.items.length > 0
    ) {
      for (let i = 0; i < allPromotions.deposit.items.length; i++) {
        result.push(allPromotions.deposit.items[i].code.toLocaleLowerCase());
      }
    }

    if (
      allPromotions.standard?.items &&
      allPromotions.standard.items.length > 0
    ) {
      for (let i = 0; i < allPromotions.standard.items.length; i++) {
        result.push(allPromotions.standard.items[i].code.toLocaleLowerCase());
      }
    }

    if (
      allPromotions.trip_in_full?.items &&
      allPromotions.trip_in_full.items.length > 0
    ) {
      for (let i = 0; i < allPromotions.trip_in_full.items.length; i++) {
        result.push(
          allPromotions.trip_in_full.items[i].code.toLocaleLowerCase()
        );
      }
    }

    if (appliedPromotions.length > 0) {
      for (let i = 0; i < appliedPromotions.length; i++) {
        result.push(appliedPromotions[i].code.toLocaleLowerCase());
      }
    }
    return result;
  };

  const checkDuplicatePromo = (promo: string) => {
    const promoCodes = extractPrmotionCodes();
    const result = promoCodes.filter((x) => x === promo.toLocaleLowerCase());
    if (result && result.length > 0) {
      return true;
    }
    return false;
  };

  return isInPaymentStage ? (
    <div>
      <Input
        placeholder="Code"
        value={promoCode}
        changeInput={changePromo}
        isError={errorMessagePromo.length > 0}
        ErrorMessage={errorMessagePromo}
        onKeyUp={keyUpHandler}
        Button={
          <Button
            text="Add"
            clickHandler={clickHandler}
            type="secondary"
            size="small"
            className="mr-2"
            isLoading={addingPromotionLoading}
            isDisabled={shouldNotLetUserAddPromotions}
          />
        }
      />
    </div>
  ) : (
    <div className="flex p-3 items-center gap-2 rounded-lg bg-yellow-800 mb-2.5">
      <div className="min-w-[1.5rem] w-6 h-6">
        <InfoIcon color="#333539" />
      </div>
      <p className="text-dark-800 text-xs font-semibold leading-4 text-left">
        Final price plus promotions will be calculated on the payment page
      </p>
    </div>
  );
};
export { PromoForm };
