import { Promotions } from 'lib/types/promotion';
import { PaymentDue } from './PaymentDue';
import { PaymentFancyDivider } from './PaymentFancyDivider';
import { PromoForm } from './Promo/PromoForm';
import { Promos } from './Promo/Promos';
import { TotalPrice } from './TotalPrice';
import { useCurrency } from 'lib/hooks/useCurrency';
import { useLocation } from 'react-router-dom';
import { Rooms } from './Rooms/Rooms';
import useTripQuery from 'lib/react-query/Queries/useTripQuery';
import { TravellersNumber } from './TravellersNumber';
import { getSelectedRoomsWithFullData } from 'lib/helper/room/room';
import { PromotionHelper } from 'lib/helper/promotion/promotion';
import { useProductQuery } from 'lib/react-query/Queries/useProductQuery';
import { stagePathConfig } from 'data/stage';
import { useAppSelector } from 'state/hooks';
import { useExperimentalFeatureVariant } from 'lib/hooks/useExperimentalFeature';
import { AB_TEST_FEATURE } from 'data/flags';

type Props = {
  promotions: Promotions;
  addPromotion: (code: string) => void;
  removePromotion: (code: string) => void;
  addingPromotionLoading: boolean;
  removingPromotionLoading: boolean;
  eitherAddOrRemoveAreLoading: boolean;
  refetch: () => any;
  hasAltMobileDesign?: boolean;
  errorMessagePromo: string;
  setErrorMessagePromo: any;
};

const PaymentDetailsComponent = ({
  promotions,
  addPromotion,
  removePromotion,
  addingPromotionLoading,
  removingPromotionLoading,
  eitherAddOrRemoveAreLoading,
  refetch,
  hasAltMobileDesign,
  errorMessagePromo,
  setErrorMessagePromo,
}: Props) => {
  const { pathname } = useLocation();
  const noOftravellers = useAppSelector((state) => state.room.noOfTravellers);
  const globalRoomsState = useAppSelector((state) => state.room.rooms);
  const { isEnabled, variant } = useExperimentalFeatureVariant(
    AB_TEST_FEATURE.due_now
  );
  const shouldShowFancyDivider =
    !isEnabled || (isEnabled && variant === 'Original');

  const activePaymentOption = useAppSelector(
    (state) => state.product.paymentOption
  );
  const { currency } = useCurrency();
  const { data: tripData } = useTripQuery();
  const { data: productData } = useProductQuery();
  const isInRoomStage = pathname === stagePathConfig.rooms;
  const travellerNumber = useAppSelector(
    (state) => state.product.travellerNumber
  );
  const roomsState = useAppSelector((state) => state.product.rooms);

  const noOfTravellers = isInRoomStage ? noOftravellers || 1 : travellerNumber;

  const selectedRooms = getSelectedRoomsWithFullData(
    roomsState,
    tripData?.result.rooms || null
  );
  const rooms = isInRoomStage ? globalRoomsState || [] : selectedRooms;

  const activePromotions =
    promotions &&
    PromotionHelper.getSelectedPromotionObject(
      promotions.promotions,
      activePaymentOption
    );

  const durationPrice = tripData
    ? tripData.result.duration.prices[currency]
    : 0;
  const productPrice = productData ? productData.result.price : 0;
  const invoice = activePromotions?.invoice;
  const retailPrice = productPrice || durationPrice;
  const retailDeposit = tripData ? tripData.result.prices.deposit[currency] : 0;
  const paidPrice = invoice?.paid ?? 0;

  const appliedPromotions = promotions.applied_promotions;

  const discount = PromotionHelper.getPromotionsDiscount(
    (invoice?.due_now ?? 0) * noOfTravellers,
    activePromotions?.items ?? []
  );

  const totalPriceWithRooms = PromotionHelper.getTotalPriceWithRooms(
    noOfTravellers,
    retailPrice,
    rooms,
    currency,
    discount
  );
  const totalPrice = isInRoomStage
    ? totalPriceWithRooms
    : invoice?.outstanding ?? 0;

  const roomsPrice = PromotionHelper.getRoomsPrice(rooms, currency);
  const duePrice = isInRoomStage
    ? PromotionHelper.getDueNow(
        noOfTravellers,
        retailPrice,
        retailDeposit,
        activePaymentOption,
        discount,
        roomsPrice
      )
    : invoice?.due_now ?? 0;

  return (
    <div
      className={
        'sm:w-[23.125rem] relative border-light-800 md:bg-light-600 rounded-md' +
        (hasAltMobileDesign ? ' border m-5 mt-0' : ' md:border')
      }
    >
      <h3 className="hidden md:block font-semibold text-lg leading-5 text-dark-800 m-5">
        Payment Details
      </h3>
      <div
        className={
          'flex flex-col gap-5 md:gap-5 m-5 mb-2.5' +
          (hasAltMobileDesign ? '' : ' mt-0')
        }
      >
        <div>
          <TravellersNumber
            noOfTravellers={noOfTravellers}
            retailPrice={retailPrice}
            paidPrice={paidPrice}
          />
          {rooms.length > 0 ? <Rooms rooms={rooms} /> : null}
        </div>
      </div>
      <div>
        <Promos
          activePromotions={activePromotions}
          appliedPromotions={appliedPromotions}
          removePromo={removePromotion}
          removingPromotionLoading={removingPromotionLoading}
          shouldNotLetUserRemovePromotions={eitherAddOrRemoveAreLoading}
          noOfTravellers={noOfTravellers}
          isInRoomStage={isInRoomStage}
          totalPriceWithRooms={totalPriceWithRooms}
          refetch={refetch}
        />
      </div>
      <div className="mx-5">
        <PromoForm
          addPromo={addPromotion}
          addingPromotionLoading={addingPromotionLoading}
          shouldNotLetUserAddPromotions={eitherAddOrRemoveAreLoading}
          errorMessagePromo={errorMessagePromo}
          setErrorMessagePromo={setErrorMessagePromo}
          allPromotions={promotions.promotions}
          appliedPromotions={appliedPromotions}
        />
      </div>
      <div className="mt-5 mx-5">
        <TotalPrice total={totalPrice} />
      </div>
      {shouldShowFancyDivider ? (
        <PaymentFancyDivider />
      ) : (
        <div className="mt-5"></div>
      )}
      <PaymentDue due={duePrice} />
    </div>
  );
};

export { PaymentDetailsComponent };
