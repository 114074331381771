import { BookingModal } from 'components/Modal/BookingModal';
import { AvailabilityList } from '../Availability/AvailabilityList';
import { DurationCarousel } from '../Duration/DurationCarousel';
import { WhenTab } from '../WhenTab/WhenTab';
import useTripQuery from 'lib/react-query/Queries/useTripQuery';
import { useEffect, useState } from 'react';
import { AvailabilityHelper } from 'lib/helper/availability/availability';
import {
  Availability,
  Month,
  SelectionAvailability,
} from 'lib/types/availability';
import { AvailabilitySubmit } from 'components/Availability/AvailabilitySubmit';
import { Duration } from 'lib/types/trip';
import { constructRoomPayloadForUpdateProduct } from 'lib/helper/room/room';
import { useUpdateProductQuery } from 'lib/react-query/Queries/useUpdateProductQuery';
import { AppToastService } from 'components/Toast/AppToastService';
import { getAvailabilityStatus } from 'components/Availability/helper';
import { MonthCarousel } from 'components/Month/MonthCarousel';
import { useAppDispatch, useAppSelector } from 'state/hooks';
import {
  setAvailability,
  setDuration,
  setFlexibleDeparture,
} from 'state/slices/product';

export type Props = {
  isOpen: boolean;
  close: () => void;
  afterSubmit: (
    flexibleDeparture: boolean,
    selectedAvailability: SelectionAvailability
  ) => void;
};

function ChangeDate({ isOpen, close, afterSubmit }: Props) {
  const dispatch = useAppDispatch();
  const hasDepartureDate = useAppSelector(
    (state) => state.product.hasDepartureDate
  );
  const selectedDuration = useAppSelector((state) => state.product.duration);

  const availability = useAppSelector((state) => state.product.availability);
  const flexibleDeparture = useAppSelector(
    (state) => state.product.flexibleDeparture
  );

  const { hasLoaded, data: dataTrip } = useTripQuery();

  const { isLoading, data, isError, submitUpdateProduct, error } =
    useUpdateProductQuery();

  const [tempSelectedDuration, setTempSelectedDuration] =
    useState<Duration | null>(selectedDuration);
  const [tempSelectedAvailability, setTempSelectedAvailability] =
    useState<SelectionAvailability>(availability);
  const [tempFlexibleDeparture, setTempFlexibleDeparture] =
    useState<boolean>(flexibleDeparture);
  const [tempMonthList, setTempMonthList] = useState<Month[] | null>(null);
  const [tempSelectedMonth, setTempSelectedmonth] = useState<Month | null>(
    null
  );

  const noAvailabilityWasChosen =
    !tempFlexibleDeparture && !tempSelectedAvailability;

  const durations = hasLoaded ? dataTrip!.result.tripDurations : [];

  const years = AvailabilityHelper.getYears(dataTrip?.result.availabilities);
  const months = AvailabilityHelper.getMonths(dataTrip?.result?.availabilities);
  const [selectedYear, setSelectedYear] = useState<string>(years[0]);

  const unit: string = dataTrip?.result.availabilities[0].unit || 'Months';

  const avilabilities: Month[] | Availability[] = AvailabilityHelper.getAll(
    dataTrip?.result.availabilities,
    selectedYear,
    tempSelectedDuration,
    unit,
    tempSelectedMonth
  );

  const travellerNumber = useAppSelector(
    (state) => state.product.travellerNumber
  );
  const roomsState = useAppSelector((state) => state.product.rooms);

  const rooms = constructRoomPayloadForUpdateProduct(roomsState);

  const availabilityIsValid = tempSelectedAvailability
    ? getAvailabilityStatus(tempSelectedAvailability) || tempFlexibleDeparture
    : tempFlexibleDeparture;

  const submit = () => {
    submitUpdateProduct({
      availability: tempSelectedAvailability,
      duration_id: tempSelectedDuration?.id || undefined,
      travellersNumber: travellerNumber,
      is_departure_flexible: tempFlexibleDeparture,
      rooms,
    });
  };

  useEffect(() => {
    const activeYear = AvailabilityHelper.getActiveYear(availability);
    activeYear && setSelectedYear(activeYear);
  }, [availability, isOpen]);

  useEffect(() => {
    if (isLoading) return;

    if (isError) {
      AppToastService.error(error?.message || 'Oops, Something went wrong!');
      return;
    }

    if (!data) return;

    if (data.success && data.result.updated) {
      close();
      AppToastService.success('Successfully changed date!');
      dispatch(setDuration(tempSelectedDuration));
      dispatch(setAvailability(tempSelectedAvailability));
      dispatch(setFlexibleDeparture(tempFlexibleDeparture));
      afterSubmit(tempFlexibleDeparture, tempSelectedAvailability);
    } else {
      AppToastService.error("Couldn't set departure date.");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, data]);

  useEffect(() => {
    if (!isOpen) {
      setTempSelectedDuration(selectedDuration);
      setTempSelectedAvailability(availability);
      setTempFlexibleDeparture(flexibleDeparture);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, availability, selectedDuration, flexibleDeparture]);

  useEffect(() => {
    if (unit === 'Days') {
      // Create a deep copy of the months array
      let monthArray: Month[] = months.map((month) => ({
        ...month,
        dates: [...month.dates],
        tags: [...month.tags],
        statuses: [...month.statuses],
        comments: [...month.comments],
      }));
      let tempArray: Month[] = [];

      for (let i = 0; i < monthArray.length; i++) {
        const dates = monthArray[i].dates.filter(
          (item) =>
            item.duration.id === tempSelectedDuration?.id &&
            item.status !== 'Not Available' &&
            !item.tags.includes('sold out')
        );

        if (dates.length > 0) {
          const tempMonth = monthArray[i];
          tempMonth.dates = dates;
          tempArray.push(tempMonth);
        }
      }

      setTempMonthList(tempArray);

      let tempMonth: Month | null = monthArray.filter((m) =>
        m.statuses.includes('Available')
      )[0];
      const tempAvailability = tempSelectedAvailability as Availability;

      for (let i = 0; i < monthArray.length; i++) {
        if (
          monthArray[i].dates.filter(
            (item) =>
              item?.availability_id === tempAvailability?.availability_id
          ).length > 0
        ) {
          tempMonth = monthArray[i];
        }
      }
      setTempSelectedmonth(tempMonth);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tempSelectedDuration,
    tempSelectedAvailability,
    tempFlexibleDeparture,
    unit,
  ]);

  useEffect(() => {
    if (hasDepartureDate || !isOpen) return;

    const yearOfFirstAvailable = AvailabilityHelper.getYearOfFirstAvailable(
      dataTrip?.result.availabilities,
      tempSelectedDuration
    );
    if (yearOfFirstAvailable) {
      setSelectedYear(yearOfFirstAvailable);
    }
  }, [
    hasDepartureDate,
    isOpen,
    tempSelectedDuration,
    dataTrip?.result.availabilities,
  ]);

  return (
    <BookingModal
      isOpen={isOpen}
      close={close}
      title={hasDepartureDate ? 'Change Date' : 'Select Date'}
      cssClasses="sm:w-[35.75rem] h-full md:h-auto"
      isScrollable={false}
      fullHeight={true}
    >
      <div className="flex flex-col overflow-y-hidden h-full md:h-auto justify-between md:justify-start">
        <div id="datemodal" className="overflow-y-auto">
          <div className="flex flex-col shrink-0">
            <DurationCarousel
              durations={durations}
              selectedDuration={tempSelectedDuration}
              setTempSelectedDuration={setTempSelectedDuration}
              unit={unit}
            />
          </div>

          {unit === 'Days' ? (
            tempMonthList && (
              <MonthCarousel
                months={tempMonthList}
                selectedMonth={tempSelectedMonth}
                setSelectedMonth={(month: Month) => setTempSelectedmonth(month)}
              />
            )
          ) : (
            <div className="p-5 !pt-0 sm:p-10 flex flex-col">
              <WhenTab
                items={years}
                selectedItem={selectedYear}
                setSelectedItem={setSelectedYear}
              />
            </div>
          )}
          <div className="p-5 !pt-0 sm:p-10 flex flex-col">
            <AvailabilityList
              key={selectedYear}
              avilabilities={avilabilities}
              selectedAvailability={tempSelectedAvailability}
              setTempSelectedAvailability={setTempSelectedAvailability}
              tempFlexibleDeparture={tempFlexibleDeparture}
              setTempFlexibleDeparture={setTempFlexibleDeparture}
              tempSelectedDuration={tempSelectedDuration}
              unit={unit}
              tempSelectedMonth={tempSelectedMonth}
            />
          </div>
        </div>
        <div className="flex flex-col shrink-0 ">
          <AvailabilitySubmit
            submitChooseDateModal={submit}
            updateProductIsLoading={isLoading}
            noAvailabilityWasChosen={noAvailabilityWasChosen}
            isDisabled={!availabilityIsValid || !hasLoaded}
          />
        </div>
      </div>
    </BookingModal>
  );
}

export { ChangeDate };
